.header-button--underline {
    background-color: rgb(83, 68, 57);
    transition: 500ms;
}

.header-button--underline-placeholder {
    height: 2px;
    justify-content: center;
    margin-top: 2%;
}

.header {
    justify-content: center;
}

.header::after {
    content: "";
    width: 100%;
    border-bottom: solid thin rgba(148, 80, 16, 0.3);
}

.header-button--logo {
    align-self: center;
    justify-content: center;
    cursor: pointer;
    display: flex;
    padding: 0 40px;
}

.header-button--logo-hover {
    transition: 200ms;
    -webkit-filter: drop-shadow(3px -5px 1px rgba(103, 63, 24, .2));
    filter: drop-shadow(3px -5px 1px rgba(103, 63, 24, .2));
}

.header-button--label {
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    transition: 0.5s;
    font-size: var(--default-size);
    padding: 10px;
}

.mobile-header {
    z-index: 1;
    height: 60px;
    background-color: rgb(187, 138, 102);
}

.mobile-menu-panel {
    background-color: rgb(187, 138, 102, 0.95);
    position: fixed;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 5% 0;
}

.mobile-menu-panel__close-button {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    right: 20px;
    background-image: url("/close_icon.svg");
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}