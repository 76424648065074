.close-button-parent {
    background-color: rgb(250, 235, 215);
    color: black;
    font-size: var(--default-size);
    justify-content: center;
    position: sticky;
    top: 0px;
    padding: 5px;
}

.close-button:hover {
    cursor: pointer;
}

.content-panel-products {
    flex-direction: row;
    overflow: hidden;
    padding-bottom: 20px;
}

.content-panel-products::after {
    content: '';
    display: block;
    flex: 999;
}

.drop-down {
    background-color: rgb(83, 68, 57);
    padding: 10px;
    margin-top: 20px;
    color: white;
    align-content: center;
    justify-content: center;
    text-align: center;
}

.drop-down-info {
    justify-content: center;
    align-content: center;
    text-align: justify;
    text-indent: 2em;
    text-justify: inter-word;
    background-color: antiquewhite;
    padding: 2%;
    line-height: 1.5em;
    font-size: 16px;
}

.extra-info {
    padding: 0 2% 2% 2%;
    text-align: center;
    font-size: var(--default-size);
}

.filter {
    flex: 1;
    padding: 0 1%;
    border-right: solid thin rgba(148, 80, 16, 0.3);
    justify-content: center;
}

.filter-button {
    padding-top: 5%;
}

.filter-button--label {
    font-family: 'Oswald', sans-serif;
    color: black;
    justify-content: center;
    font-size: var(--default-size);
    text-align: center;
    white-space: nowrap;
    padding: 1.5% 0;
}

.filter-button--label-clicked {
    color: black;
    font-size: 19px;
    font-weight: bold;
}

.filter-button--underline {
    background-color: rgb(83, 68, 57);
    transition: 0.5s;
    height: 100%;
}

.filter-button--underline-placeholder {
    height: 2px;
    justify-content: center;
    margin-top: 2%;
}

.underlined-button {
    background-color: rgb(204, 176, 154);
    border-radius: 10px;
    color: black;
    cursor: pointer;
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    font-weight: bold;
    justify-content: center;
    padding: 5px;
    transition: 0.5s;
}

.underlined-button-placeholder {
    height: 2px;
    justify-content: center;
}

.underlined-button--underline {
    background-color: rgb(83, 68, 57);
    transition: 0.5s;
    height: 100%;
}

.item-card {
    flex-direction: column;
    transition: 0.5s;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.item-card:hover {
    box-shadow: 0 4px 8px 0px rgba(0, 0, 0, 0.4), 5px 6px 20px 0 rgba(0, 0, 0, 0.4);
    transform: scale(1.03);
    cursor: pointer;
}

.item-card--container {
    flex: 1 1 16.65% !important;
    padding: 10px;
}

.item-card--title {
    text-align: center;
    align-items: center;
    padding: 5%;
    background-color: antiquewhite;
    font-family: 'Oswald', sans-serif;
    font-size: var(--default-size);
    justify-content: center;
    flex: 1;
    min-height: 80px;
}

.item-page-section__title {
    padding-bottom: 2%;
    justify-content: center;
    font-size: 24px;
    text-align: center;
    font-weight: 900;
}

.large-image-style>div>div {
    background-color: rgb(230, 205, 182);
}

.limited-text-area {
    padding-top: 20px;
    font-family: 'Raleway', sans-serif;
    font-size: var(--label-size);
}

.page-button {
    background-color: transparent;
    border: 1px solid rgb(83, 68, 57);
    color: rgb(83, 68, 57);
    height: 45px;
    width: 45px;
    font-size: 20px;
}

.page-button:hover {
    background-color: rgb(83, 68, 57);
    transition: 500ms;
    color: white;
    cursor: pointer;
}

.page-button-selected {
    background-color: rgb(83, 68, 57);
    border: 1px solid rgb(83, 68, 57);
    color: rgb(83, 68, 57);
    height: 45px;
    width: 45px;
    color: white;
    font-size: 20px;
}

.product-container {
    flex: 5;
    padding: 2%;
}

.product-page-content {
    flex-direction: row;
    flex: 1;
}

.ReactCollapse--collapse {
    width: 100%;
    transition: height 500ms;
}

.ReactCollapse--content {}

.show-more-button {
    flex: 1;
    align-content: flex-end;
}

.single-product-image-section {
    flex: 1;
    display: block !important;
}

.single-product-photo {
    justify-content: center;
    align-content: baseline;
    border: solid thin rgba(148, 80, 16, 0.3);
}

@media (max-width: 1500px) {
    .item-card--container {
        flex: 1 1 20% !important;
    }
}

@media (max-width: 1200px) {
    .item-card--container {
        flex: 1 1 25% !important;
    }
}

@media (max-width: 1000px) {
    .item-card--container {
        flex: 1 1 33% !important;
    }

    .product-container {
        flex: 4;
    }
}

@media (max-width: 870px) {
    .extra-info {
        padding-top: 10px;
        font-size: 16px;
    }

    .filter {
        justify-content: center;
    }

    .item-card--container {
        flex: 1 1 33.3% !important;
    }

    .product-container {
        flex: 1;
    }

    .product-page-content {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .item-card--container {
        flex: 1 1 50% !important;
    }

    .single-product-details {
        flex-direction: column;
    }

    .single-product-photo {
        padding: 0 20% 0 20%;
    }
}