.contacts-form-input {
    justify-content: center;
    padding: 5px;
}

.contacts-form-input__button {
    background: rgb(180, 141, 105) !important;
    width: 30%
}

.left-section-info {
    font-size: var(--default-size);
}

.contacts-info {
    padding: 3%
}

.contacts-info__info-line {
    justify-content: center;
}

.contacts-info__info-line--bold {
    font-weight: bold;
}

.contacts-page-section {
    flex: 1;
    flex-direction: column
}

.contacts-page-section--margin-right {
    margin-right: 8%;
}

.contacts-page-section--margin-left {
    margin-left: 8%;
}

.contacts-page-section__title {
    justify-content: center;
    align-items: center;
    font-size: var(--title-size);
}

@media (max-width: 700px) {
    .contacts-info {
        padding: 0
    }
    .contacts-page-section--margin-left {
        margin-left: 0;
    }
    .contacts-page-section {
        flex: 0;
        padding: 10px 0
    }
    .contacts-page-section__title {
        padding-top: 30px 0
    }
    .sections {
        flex-direction: column;
    }
}

@media (max-width: 1200px) {
    .contacts-page-section--margin-right {
        margin-right: 0;
    }
    .contacts-page-section--margin-left {
        margin-left: 0;
    }
}