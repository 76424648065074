.about-us-pictures {
    padding: 0 50px 50px 50px;
    flex: 1;
}

.about-us-picture {
    flex: 1;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.about-us-text {
    justify-content: center;
    padding: 0px 10% 30px 10%;
    font-size: var(--default-size);
    text-align: center;
}

.about-us-title {
    justify-content: center;
    font-size: var(--title-size);
    padding: 30px 0;
}

.picturePath1 {
    background-image: url("/cow15-min.jpg");
    margin-right: 25px;
}

.picturePath2 {
    background-image: url("/cow11-min.jpg");
    margin-left: 25px;
}