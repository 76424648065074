:root {
  --title-size: 30px;
  --default-size: 18px;
  --secondary-size: 16px;
  --label-size: 13px;
}

.footer {
  font-size: var(--label-size);
  text-align: center;
  padding: 1px 0;
  background-color: rgb(219, 160, 100);
  /* rgb(103, 63, 24); */
}

.page-template {
  font-family: 'Raleway', sans-serif;
  background-color: rgb(230, 205, 182);
  min-height: 100vh;
}

.PrivateNotchedOutline-root-1 {
  background: antiquewhite;
}

.MuiInputBase-input {
  z-index: 1000;
}

.MuiTableCell-root {
  font-size: 16px !important;
}