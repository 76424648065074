.main-page {
    padding: 0 60px;
}

.main-page-description {
    justify-content: center;
    font-size: var(--title-size);
    margin: 15px 0;
}

.main-page-photo {
    flex: 1;
    background-image: url("/cow_main.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 20px;
}

.main-page-title {
    justify-content: center;
    font-size: 60px;
    color: darkslategray;
    padding: 2% 0;
}

@media (max-width: 870px) {
    .main-page-photo {
        margin-top: 0px;
    }
    .main-page {
        padding: 0px;
    }
}